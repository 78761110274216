import React, { useEffect, useRef } from "react"
import { getNbDodos } from "../../../utils"
import {
  Wrapper,
  Title,
  BigNumber,
  Headline,
  Bursts
} from "./index.styles"
import Curtains from "../../Curtains"
import Since from "../../Since"
import withBurst from "../../../lib/withBurst"
import Music from "../../Music"
import FirebaseState from "../../../state/FirebaseState"
import DraggablePictures from "../../DraggablePictures"
import Nav from "../IndexPage/components/Nav"

const IndexPage = props => {
  const nbDodos = getNbDodos()
  const wrapper = useRef()
  const { burst } = withBurst()

  useEffect(() => {
    setTimeout(() => {
      const interval = setInterval(() => {
        if (!wrapper.current) return
        const [x, y] = [Math.random(), Math.random()].map(d => d * 100)
        burst(wrapper.current, `${x}%`, `${y}%`)
      }, 1000)
      return () => {
        clearInterval(interval)
      }
    }, 3000)
  }, [])

  return (
    <>
      <Curtains />
      <Nav />
      <Music />
      <DraggablePictures />
      <Wrapper innerRef={wrapper}>
        <Title>
          <div>Les Amoureux</div>
          <div>Axelle & Benjamin</div>
        </Title>
        <Since />
        {nbDodos >= 0 && (
          <>
            <Headline>Il nous reste</Headline>
            <BigNumber>
              <span>{nbDodos}</span> dodos
            </BigNumber>
          </>
        )}
        <Bursts ref={wrapper} />
      </Wrapper>
    </>
  )
}

IndexPage.propTypes = {}

IndexPage.defaultProps = {}

export default IndexPage
