import React, { useEffect, useRef } from "react"
import { getNbDodos } from "../../../utils"
import {
  Wrapper,
  Bursts,
  Title,
  BigNumber,
  Headline,
  KohLantaButton
} from "./index.styles"
import Curtains from "../../Curtains"
import Since from "../../Since"
import Quote from "./components/Quote"
import withBurst from "../../../lib/withBurst"
import Music from "../../Music"
import DraggablePictures from "../../DraggablePictures"
import Nav from "./components/Nav"
import { Link } from "gatsby"

const IndexPage = (props) => {
  const nbDodos = getNbDodos()
  const wrapper = useRef()

  const { burst } = withBurst()

  const shouldDisplayKohLanta = new Date().getDay() === 5 && new Date().getHours() >= 20

  useEffect(() => {
    const interval = setInterval(() => {
      if (!wrapper.current) return
      const [x, y] = [Math.random(), Math.random()].map(d => d * 100)
      burst(wrapper.current, `${x}%`, `${y}%`)
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <>
      <Curtains />
      <Quote />
      <Nav />
      <DraggablePictures />
      <Music />
      <Wrapper>
          <Title>
            <div>Les Amoureux</div>
            <div>Axelle & Benjamin</div>
          </Title>
          <Since />
          {
            nbDodos >= 0 && <>
              <Headline>Il nous reste</Headline>
              <BigNumber>
                <span>{nbDodos}</span> dodos
              </BigNumber>
            </>
          }
        <Bursts ref={wrapper} />
        { shouldDisplayKohLanta && <KohLantaButton to='/koh-lanta'>
          <div />
        </KohLantaButton> }
      </Wrapper>
    </>
  )
}

IndexPage.propTypes = {}

IndexPage.defaultProps = {}

export default IndexPage
