import React from "react"
import Layout from "../layouts/base"
import IndexPage from "../components/pages/IndexPage"
import PublicView from '../components/pages/PublicView'
import AuthenticationState from "../state/AuthenticationState"
import CurrentUserState from "../state/CurrentUserState"
import Loader, { Loader100vh } from "../components/UI/Loader"
import { graphql } from 'gatsby'

const Index = () => {
  const { isAuthenticated } = AuthenticationState.useContainer()
  const { loading } = CurrentUserState.useContainer()
  if (loading) return <Loader100vh />
  if (isAuthenticated) return <IndexPage />
  return <PublicView />
}

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fixed(width: 234, height: 202) {
        ...GatsbyImageSharpFixed
      }
    }
}
`

export default () => {
  return <Layout>
    <Index />
  </Layout>
}
