import React from 'react'
import { Link } from 'gatsby'
import { Chat, Gallery, Video, Task, ChatOption, Multimedia, Login } from "grommet-icons"
import CurrentUserState from '../../../../../state/CurrentUserState'
import { Wrapper } from './index.styles'
import FirebaseState from '../../../../../state/FirebaseState'

const Nav = (props) => {
  const { currentUser } = CurrentUserState.useContainer()
  const { firebase } = FirebaseState.useContainer()

  const onLogin = () => {
    var provider = new firebase.auth.GoogleAuthProvider()
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => {})
      .catch(error => {
        console.error(error)
      })
  }

  return <Wrapper>
    { currentUser.admin && <Link to="/messages">
      <div>
        <Chat />
      </div>
    </Link> }
    <Link to="/album">
      <div>
        <Gallery color="#fff" />
      </div>
    </Link>
    <Link to="/videos">
      <div>
        <Multimedia color="#fff" />
      </div>
    </Link>
    { currentUser.admin && <Link to="/watch">
      <div>
        <Video color="#fff" />
      </div>
    </Link> }
    { currentUser.admin && <Link to="/todo">
      <div>
        <Task color="#fff" />
      </div>
    </Link> }
    {
      currentUser.admin && <Link to='/message-du-jour'>
        <div>
          <ChatOption color='#fff' />
        </div>
      </Link>
    }
    {
      !currentUser.uid && <div onClick={onLogin}>
        <div>
          <Login color='#fff' />
        </div>
      </div>
    }
  </Wrapper>
}

Nav.propTypes = {

}

Nav.defaultProps = {

}

export default Nav
