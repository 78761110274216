import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { getTimeSinceFirstDayIn, getFormattedDateDiff, firstDay } from "../../utils"

const Wrapper = styled.div`
  width: 100%;
  background-color: rgba(255,255,255,0.05);
  box-shadow: 2px 2px 3px rgba(255,255,255,0.05);
  border-radius: 8px;
  max-width: 400px;
  margin-top: 60px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Dancing script', cursive;
  margin-bottom: 30px;
  span {
    font-weight: 600;
  }
  > :first-child {
    text-align: center;
    font-size: 29px;
    font-weight: bold;
    margin-bottom: 10px;
    font-weight: 500;
  }
`

const Splits = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  > * {
    margin-left: 0px;
    width: 94px;
    display: flex;
    span {
      display: block;
      font-size: 25px;
      width: 40px;
      text-align: center;
    }
  }
`

const Since = props => {
  const [dateIntervals, setDateIntervals] = useState()
  const [secondsSince, setSecondsSince] = useState(
    getTimeSinceFirstDayIn("second")
  )
  

  useEffect(() => {
    const interval = setInterval(() => {
      setDateIntervals(
        getFormattedDateDiff(firstDay, new Date())
      )
      setSecondsSince(getTimeSinceFirstDayIn("second"))
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Wrapper>
      <div>Depuis</div>
      { dateIntervals && <Splits>
        { dateIntervals[0] > 0 && <div>
          <span>{dateIntervals[0]}</span> années
        </div> }
        { dateIntervals[1] > 0 && <div>
          <span>{dateIntervals[1]}</span> mois
        </div> }
        { dateIntervals[2] > 0 && <div>
          <span>{dateIntervals[2]}</span> semaines
        </div> }
        { dateIntervals[3] > 0 && <div>
          <span>{dateIntervals[3]}</span> jours
        </div> }
        { dateIntervals[4] > 0 && <div>
          <span>{dateIntervals[4]}</span> heures
        </div> }
        { dateIntervals[5] > 0 && <div>
          <span>{dateIntervals[5]}</span> minutes
        </div> }
        { dateIntervals[6] > 0 && <div>
          <span>{dateIntervals[6]}</span> secondes
        </div> }
      </Splits> }
      <div>
        Mais en jours de nous, ça fait{" "}
        <span>{Math.floor(secondsSince) * 1234}</span> jours
      </div>
      <div>Et oui, ça fait beaucoup! Le temps passe si vite!</div>
    </Wrapper>
  )
}

export default Since
