import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FirebaseState from '../../state/FirebaseState'

const Wrapper = styled.div`
  position: fixed;
  z-index: 10;
  top: 20px;
  right: calc(50% - 66px);
  height: 100px;
  width: 100px;
  opacity: 0;
  position: fixed;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  opacity: 0.2;
`

const Music = ({ tag }) => {
  const { firebase } = FirebaseState.useContainer()
  const [TAG, setTAG] = useState(tag)

  useEffect(() => {
    firebase
    .firestore()
    .collection("musics")
    .where("date", ">=", new Date(Date.now() - 24 * 3600 * 1000))
    .where("date", "<", new Date())
    .get().then((snapshot) => {
      if (snapshot.docs.length === 0) return
      setTAG(snapshot.docs.map(d => d.data())[0].tag)
    })
  }, [])

  if (!TAG) return null
  return <Wrapper>
    <iframe width="100px" height="100px"
  src={`https://www.youtube.com/embed/${TAG}?autoplay=1`}>
  </iframe>
  </Wrapper>
}

Music.propTypes = {

}

Music.defaultProps = {

}

export default Music
