import React, { useState, useEffect } from "react"
import Typewriter from 'typewriter-effect'
import styled, { css } from 'styled-components'
import CurrentUserState from "../../../../state/CurrentUserState"
import FirebaseState from "../../../../state/FirebaseState"
import Div100vh from "react-div-100vh"
import { colors } from "../../../../theme"
import { Close } from "grommet-icons"

const Wrapper = styled(Div100vh)`
  position: fixed;
  background-image: linear-gradient(to left, ${colors.primary}, ${colors.secondary});
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  overflow-y: scroll;
  z-index: 101;
`

const ExitButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`

const Title = styled.div`
  font-family: 'Dancing Script', cursive;
  width: 100%;
  text-align: center;
  font-size: 28px;
  margin-top: 20px;
`

const Content = styled.div`
  position: relative;
  max-width: 360px;
  margin-top: 60px;
  margin: 0 auto;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding-bottom: 90px;
  > :nth-child(2) {
    opacity: 0;
  }
  > :nth-child(3) {
    position: absolute;
    top: 80px;
    left: 0;
    width: calc(100% + 5px);
  }

  > :last-child {
    margin-top: 40px;
    align-self: flex-end;
    font-style: italic;
    opacity: 0;
    ${props => props.typingOver && css`
      opacity: 1;
    `}
  }
`

const Quote = props => {
  const { currentUser } = CurrentUserState.useContainer()
  const { firebase } = FirebaseState.useContainer()
  const [quote, setQuote] = useState()
  const [typingOver, setTypingOver] = useState(false)
  const [displayed, setDisplayed] = useState(true)

  useEffect(() => {
    if (quote && quote.value) {
      firebase.analytics().logEvent('read_mdj');
      updateCurrentQuote()
    }
  }, [quote])

  useEffect(() => {
    firebase
    .firestore()
    .collection("quotes")
    .where("date", ">=", new Date(Date.now() - 24 * 3600 * 1000))
    .where("date", "<", new Date())
    .where("seen", "==", false)
    .get().then((snapshot) => {
      if (snapshot.docs.length === 0) return
      const filtered = snapshot.docs.map(d => ({ id: d.id, ...d.data() })).filter(d => d.createdBy !== currentUser.uid)
      if (!(filtered && filtered[0])) return
      setQuote(filtered[0])
    }).catch(err => console.error(err))
  }, [])

  const updateCurrentQuote = async () => {
    await firebase.firestore().collection("quotes")
      .doc(quote.id)
      .update({ seen: true, seenAt: new Date() })
  }

  if (!(currentUser.permissions && currentUser.permissions.quotes)) return null
  if (!quote || !displayed) return null
  return (
    <Wrapper>
      <ExitButton onClick={() => setDisplayed(false)}>
        <Close color='#fff' />
      </ExitButton>
      <Content typingOver={typingOver}>
        <Title>Message du jour</Title>
        <div>«{quote.value}»</div>
        <div>
          <Typewriter
            cursor="none"
            onInit={typewriter => {
              typewriter
                .callFunction(state => {
                  state.elements.cursor.style.display = "none"
                })
                .changeDelay(40)
                .pause(1000)
                .typeString(`« ${quote.value} »`)
                .pause(1500)
                .callFunction(() => {
                  setTypingOver(true)
                })
                .start()
            }}
          />
        </div>
        <div>- {quote.author}</div>
      </Content>
    </Wrapper>
  )
}

Quote.propTypes = {}

Quote.defaultProps = {}

export default Quote
