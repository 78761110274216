let mojs = {}
if (typeof window !== 'undefined') {
  mojs = require('@mojs/core')

  class Heart extends mojs.CustomShape {
    getShape () { return '<path d="M92.6 7.4c-10-9.9-26-9.9-35.9 0l-4.4 4.3a3.4 3.4 0 0 1-4.7 0l-4.3-4.3c-10-9.9-26-9.9-35.9 0a25 25 0 0 0 0 35.5l22.4 22.2 13.5 13.4a9.5 9.5 0 0 0 13.4 0L70.2 65 92.6 43a25 25 0 0 0 0-35.5z"/>' }
    getLength () { return 200 } // optional
  }
  mojs.addShape('heart', Heart)
}

const dispose = function () {
  setTimeout(() => {
    if (!this.el.parentNode) return
    this.el.parentNode.removeChild(this.el)
  }, 10)
}

export default () => {
  const burst = (parent, x, y, shape = 'heart') => {
    const burst1 = new mojs.Burst({
      parent,
      left: x,
      top: y,
      count: 'rand(4, 6)',
      radius: { 20: 130 },
      children: {
        shape,
        fill: [ 'white', '#FF99AA' ],
        scale: { 1: 0.2 },
        opacity: { 1: 0 },
        radius: 'rand(30, 70)',
        angle: { 0: 50 },
        delay: 'rand(0, 150)'
      }
    })

    burst1
      .tune({ onComplete: dispose })
      .generate()
      .replay()
  }

  return {
    burst
  }
}