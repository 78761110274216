import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  z-index: 100;
  color: white;
  top: 0px;
  left: 0px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 6px 0;
  > * {
    background-color: rgba(255, 255, 255, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 70px;
    cursor: pointer;
    border-bottom: 1px solid #00000033;

    > div {
      height: 24px;
      width: 24px;
    }
    :hover, :active {
      background-color: #c11a5b;
      svg {
        fill: #fff;
        stroke: #fff;
      }
    }
    transition: background-color 0.3s;
  }
  > :last-child {
    border-radius: 0 0 6px 0;
    border-bottom: none;
  }
  svg {
    fill: #000;
    stroke: #000;
    transition: all 0.3s;
  }

  @media screen and (max-width: 500px) {
    top: initial;
    bottom: 0px;
    background-color: #fff;
    flex-direction: row;
    width: 100%;
    border-radius: 0;
    > * {
      height: 70px;
      flex: 1;
      width: 100%;
      border-bottom: none; 
      border-right: 1px solid #00000033;
    }
    > :last-child {
      border-right: none;
    }
  }
`