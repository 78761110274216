import moment from 'moment'

export const firstDay = new Date('2020-01-18')

export const getNbDodos = () => {
  const nextDate = new Date('2020-03-14')
  const today = new Date()
  const diffInDays = Math.floor((nextDate - today) / 1000 / 60 / 60 / 24 + 1)
  return diffInDays
}

export const getTimeSinceFirstDayIn = (type) => {
  const firstDay = new Date('2020-01-18')
  const now = new Date()
  const diff = now - firstDay
  if (type === 'minute') {
    return diff / 1000 / 60
  }
  if (type === 'second') {
    return diff / 1000
  }
  if (type === 'hour') {
    return diff / 1000 / 60 / 60
  }
  if (type === 'day') {
    return diff / 1000 / 60 / 60 / 24
  }
}

export const getFormattedDateDiff = function(date1, date2) {
  let b = moment(date1)
  let a = moment(date2)
  let intervals = ['years','months','weeks','days','hours','minutes','seconds']

  return intervals.map(interval => {
    let diff = a.diff(b, interval)
    b.add(diff, interval);
    return diff
  })
}