import React, { useEffect, useState } from "react"
import { animated, useSpring } from "react-spring"
import styled from "styled-components"
import { colors } from '../../theme'

const Wrapper = styled.div`
  position: fixed;
  height: 110%;
  width: 110%;
  left: -5%;
  top: -5vh;
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
  > :nth-child(n) {
    height: 50%;
    width: 50%;
    background-color: tomato;
  }

  > :nth-child(2) {
    left: 50%;
  }
`

const Curtains = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [finished, setFinished] = useState(false)
  const props = useSpring({
    xy: [
      isOpen && typeof window !== 'undefined' ? window.innerWidth / 2 : 0,
      isOpen && typeof window !== 'undefined'  ? window.innerHeight / 2 : 0,
    ],
    backgroundColor: isOpen ? colors.secondary : colors.secondary,
    scale: isOpen ? 1 : 0.5,
    contentOpacity: isOpen ? 1 : 0,
    onRest: () => {
      setFinished(true)
    },
    config: { mass: 1, friction: 23 },
  })

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true)
    }, 500)
  }, [])

  if (finished) return null
  return (
    <Wrapper>
      <animated.div
        style={{
          ...props,
          transform: props.xy.interpolate(
            (x, y) => `translate(${-x}px, ${-y}px)`
          ),
        }}
      />
      <animated.div
        style={{
          ...props,
          transform: props.xy.interpolate(
            (x, y) => `translate(${x}px, ${-y}px)`
          ),
        }}
      />
      <animated.div
        style={{
          ...props,
          transform: props.xy.interpolate(
            (x, y) => `translate(${-x}px, ${y}px)`
          ),
        }}
      />
      <animated.div
        style={{
          ...props,
          transform: props.xy.interpolate(
            (x, y) => `translate(${x}px, ${y}px)`
          ),
        }}
      />
    </Wrapper>
  )
}

Curtains.propTypes = {}

Curtains.defaultProps = {}

export default Curtains
