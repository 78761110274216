import React, { useState, useEffect, useRef, useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useSpring, config, animated } from "react-spring"
import CurrentUserState from '../../state/CurrentUserState'
import Img from "gatsby-image"
import { graphql, StaticQuery } from "gatsby"

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 20px;
  width: 250px;
  height: 100vh;
  background-color: #ffffff05;
  z-index: 1;
`

const Container = styled(animated.div)`
  position: fixed;
  right: 20px;
  z-index: -1;
`

const Picture = styled.div`
  height: 250px;
  width: 250px;
  padding: 8px 8px 40px 8px;
  margin-bottom: 20px;
  background-color: white;
  > * {
    height: 100%;
    width: 100%;
  }
`

const DraggablePictures = ({ data }) => {
  const [touching, setTouching] = useState(false)
  const [currentY, setCurrentY] = useState(0)
  const { currentUser } = CurrentUserState.useContainer()
  const wrapper = useRef()
  const canSeePrivatePicture = currentUser.admin

  const PICTURES = useMemo(() => [
    canSeePrivatePicture && { src: data.p1, private: true },
    canSeePrivatePicture && { src: data.p2, private: true },
    { src: data.p3, private: false },
    { src: data.p4, private: false },
    canSeePrivatePicture && { src: data.p5, private: true },
    { src: data.p6, private: false },
  ].filter(d => d), [])

  const spring = useSpring({
    y: touching ? (currentY / window.innerHeight) * 270 * (PICTURES.length + 1) : 0,
    config: config.wobbly,
  })

  useEffect(() => {
    wrapper.current.addEventListener("touchstart", onStart, { passive: false })
    wrapper.current.addEventListener("touchmove", onMove, { passive: false })
    wrapper.current.addEventListener("touchend", onRelease, { passive: false })
    wrapper.current.addEventListener("touchleave", onRelease, { passive: false })
    wrapper.current.addEventListener("pointerdown", onStart, { passive: false })
    wrapper.current.addEventListener("pointermove", onMove, { passive: false })
    wrapper.current.addEventListener("pointerup", onRelease, { passive: false })
    wrapper.current.addEventListener("pointerleave", onRelease, { passive: false })
    return () => {
      wrapper.current.removeEventListener("touchstart", onStart, { passive: false })
      wrapper.current.removeEventListener("touchmove", onMove, { passive: false })
      wrapper.current.removeEventListener("touchend", onRelease, { passive: false })
      wrapper.current.addEventListener("touchleave", onRelease, { passive: false })
      wrapper.current.removeEventListener("pointerdown", onStart, { passive: false })
      wrapper.current.removeEventListener("pointermove", onMove, { passive: false })
      wrapper.current.removeEventListener("pointerup", onRelease, { passive: false })
      wrapper.current.addEventListener("pointerleave", onRelease, { passive: false })
    }
  }, [wrapper && wrapper.current])

  const onStart = e => {
    e.preventDefault()
    e.stopPropagation()
    setTouching(true)
    const y = e.touches ? e.touches[0].clientY : e.clientY
    setCurrentY(y)
  }

  const onMove = e => {
    e.preventDefault()
    const y = e.touches ? e.touches[0].clientY : e.clientY
    setCurrentY(y)
  }

  const onRelease = e => {
    e.preventDefault()
    e.stopPropagation()
    setTouching(false)
    setCurrentY(0)
  }

  return (
    <Wrapper ref={wrapper}>
      <Container
        style={{
          top: `calc(-270px * ${PICTURES.length})`,
          transform: spring.y.interpolate(y => `translateY(${y}px)`),
        }}
      >
        { PICTURES.map((p, i) => <Picture key={i} src={p.src}>
          <Img fixed={p.src.childImageSharp.fixed} loading='lazy' />
        </Picture>) }
      </Container>
    </Wrapper>
  )
}

DraggablePictures.propTypes = {}

DraggablePictures.defaultProps = {}

const query = graphql`
  query IndexQuery {
    p1: file(relativePath: { eq: "pictures/p1.jpeg" }) {
      ...squareImage
    }
    p2: file(relativePath: { eq: "pictures/p2.jpeg" }) {
      ...squareImage
    }
    p3: file(relativePath: { eq: "pictures/p3.jpeg" }) {
      ...squareImage
    }
    p4: file(relativePath: { eq: "pictures/p4.jpeg" }) {
      ...squareImage
    }
    p5: file(relativePath: { eq: "pictures/p5.jpeg" }) {
      ...squareImage
    }
    p6: file(relativePath: { eq: "pictures/p6.jpeg" }) {
      ...squareImage
    }
  }
`


export default () => (
  <StaticQuery
    query={query}
    render={data => <DraggablePictures data={data} />}
  />
)
