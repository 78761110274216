import styled from 'styled-components'
import { colors } from '../../../theme'
import Button from '../../UI/Button'
import Div100vh from 'react-div-100vh'

export const Wrapper = styled(Div100vh)`
  background-image: linear-gradient(to left, ${colors.primary}, ${colors.secondary});
  overflow: hidden;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 70px);
  color: ${colors.primayFont};
  padding: 0 20px;
  z-index: 2;
  padding-left: 70px;
  @media screen and (max-width: 500px) {
    padding: 0 20px 70px 20px;
  }
`

export const Bursts = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  z-index: 0;
`

export const Title = styled.div`
  position: absolute;
  top: 50px;
  width: 100%;
  text-align: center;
  font-size: 52px;
  font-family: 'Dancing script', cursive;
  font-weight: 500;
  > :nth-child(2) {
    font-weight: 400;
    margin-top: 10px;
    font-size: 20px;
  }
`

export const Headline = styled.div`
  margin-bottom: 20px;
`

export const BigNumber = styled.div`
  font-size: 34px;
  animation: animate-number 0.2s alternate linear infinite; 
  animation-delay: 3s;
  margin-bottom: 24px;
  @keyframes animate-number {
    from {
      transform: rotate(-3deg);
    }
    to {
      transform: rotate(3deg);
    }
  }
`
